
import * as ipxStaticRuntime$MKtBFyjpFL from '/opt/buildhome/repo/node_modules/.pnpm/@nuxt+image@1.7.0/node_modules/@nuxt/image/dist/runtime/providers/ipxStatic'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "ipxStatic",
  "domains": [
    "cms-test-assets.americanbookcompany.com"
  ],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['ipxStatic']: { provider: ipxStaticRuntime$MKtBFyjpFL, defaults: {} }
}
        